import React from 'react';
// import { Link } from "gatsby"

import { CuponDescuento } from '../components/CuponDescuento';

const DescuentoPage = () => {
    return <CuponDescuento></CuponDescuento>;
};

export default DescuentoPage;
