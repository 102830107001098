import styled, { keyframes } from 'styled-components';
import { media } from '../../style';

export const slide = keyframes`   
    0% {
        transform:translateX(-25%);
    }
    100%{
        transform:translateX(25%);
    }
`;

export const Bg = styled.div`
    animation: ${slide} 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
`;

export const Bg2 = styled.div`
    animation-direction: alternate-reverse;
    animation-duration: 4s;
`;

export const Bg3 = styled.div`
    animation-duration: 5s;
`;

export const Content = styled.div`
    background-color:rgba(255,255,255,.8);
    border-radius:4.25em;
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box:
    left:50%;
    padding:10vmin;
    position:fixed;
    text-align:center;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
`;

export const Coupon = styled.h1`
    font-family: monospace;
`;

export const Instrucciones = styled.h3``;

export const Container = styled.div`
    margin: 0;
    height: 100%;
`;
