import React, { FunctionComponent } from 'react';
import {
    Bg,
    Bg2,
    Bg3,
    Content,
    Coupon,
    Container,
    Instrucciones,
} from './style';

export const CuponDescuento: FunctionComponent<{}> = () => {
    const createCoupon = () => {
        var months = [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC',
        ];
        var date = new Date();
        var monthName = months[date.getMonth()];

        return monthName + date.getFullYear();
    };

    return (
        <Container>
            <Bg></Bg>
            <Bg2></Bg2>
            <Bg3></Bg3>
            <Content>
                <Instrucciones>
                    Copia y pega este cupón en CUALQUIERA de mis cursos en Udemy
                    y obtén un 60% de descuento!
                </Instrucciones>
                <Coupon>{` ${createCoupon()}`}</Coupon>
            </Content>
        </Container>
    );
};
